<template>
  <div class="hello">
    <h1 style="text-align: center">{{ msg }}</h1>
    <div class="controls">
      <button @click="togglePlay">
        {{ isPlaying ? '暂停播放' : '播放文字' }}
      </button>
      <button @click="changeSpeed">倍速播放 ({{ speed }}x)</button>
      <button @click="resetSpeed">恢复正常速度</button>
      <button @click="toggleLoop">
        {{ isLooping ? '关闭循环' : '开启循环' }}
      </button>
    </div>
    <ul style="list-style: none">
      <li>
        每一笔买入都是试错，无非就是涨或者跌，百分之五十的概率，不要想着买进入就是赚钱，一定是抱着试错的心态去，在这个前提下一定要做相对来说大概率的操作，比如后续有消息刺激，或者题材处于发酵阶段，大盘涨多跌少等
      </li>
      <li>
        每一笔买入都是试错，无非就是涨或者跌，百分之五十的概率，不要想着买进入就是赚钱，一定是抱着试错的心态去，在这个前提下一定要做相对来说大概率的操作，比如后续有消息刺激，或者题材处于发酵阶段，大盘涨多跌少等
      </li>
      <li>
        每一笔买入都是试错，无非就是涨或者跌，百分之五十的概率，不要想着买进入就是赚钱，一定是抱着试错的心态去，在这个前提下一定要做相对来说大概率的操作，比如后续有消息刺激，或者题材处于发酵阶段，大盘涨多跌少等
      </li>
      <li>
        板块与板块之间是竞争关系，板块内的个股是合作关系。相同题材梯队之间也是合作关系，但是梯队内的不同题材的个股又是竞争关系
      </li>
      <li>
        在一个相同的板块内，个股之间存在补涨，同样的，板块与板块之间也存在补涨，但是这里还有一些不一样的逻辑，个股之间的补涨是得预期这个板块会走成主线，他会不断的走强发酵，而板块之间的补涨则是得主流板块走弱了才会出现，而不是在主流板块强势的时候进行，一定要注意其中衔接性的不同
      </li>
      <li>
        第一步，先确定主流板块，再确定板块里面的核心。如果当前板块的核心一直都是一字板导致买不上，那么可以考虑做当前板块的补涨个股，这就是我们所说的板块内个股低位补涨逻辑，只要这个板块在发酵，那么就可以做相同板块内的低位补涨，这个时候千万不要去做非主流板块，非主流板块只能在主流板块确认为鱼尾行情的时候，才能去做，这就是板块之间的高低切换，与相同板块内个股低位补涨逻辑是不一样的，哪些点不一样呢？主要是有以下几点，相同板块内个股低位补涨逻辑是确认当前板块为主流板块且它在一直不断的发酵走强，那么就可以做低位补涨，但是板块之间的补涨就是当前主流板块已经走弱了，或者即将走弱了，那么就可以去做非主流板块，比如2025年2月份的deepseek和机器人板块，这两个板块核心个股一直在走强，那么这个时候龙头的位置太高了，你就可以去做相同逻辑的低位补涨，这个时候你千万不要去做非主流板块，因为资金都在deepseek和机器人，你去其他板块就是赚不到钱还要亏钱，但是当这两个板块处于鱼尾行情的时候，你就要注意其他低位板块的衔接性，比如白酒，小金属就有赚钱效应
      </li>
      <li>
        千万不要有主线板块的时候不做去做其他板块，如果当下主线板块你都把握不住，你怎么能确保你能抓到下一个主线呢？你想做其他板块可以做，但是一定得等到主线板块死亡或者接近死亡的时候再来考虑，不敢做主线的高位，可以挖掘一下主线的补涨，因为只有高位的涨，没有低位补涨，主线也走不长远
      </li>
      <li>
        主流主流还是主流，核心核心还是核心，核心的影响力是巨大的，甚至能影响整个大盘的走势，不管是打板，低吸，接力都要做核心，核心票可以是当前主流板块的核心，也可以是行业里面的龙头企业，也可以是有一定的辨识度，比如以前的领涨老龙头，当然最好是当这个核心票起来之后对大盘有带动作用，对板块有带动作用，对产业链也有带动作用，这个时候他可以判断为核心，比如25年的2月8号，比亚迪涨停了，他就带动了光学电子板块的联创电子，欧菲光，以及无人驾驶的万集科技，还有迪链的德赛西威等股票，这个时候可以考虑介入核心，同样的，核心的成交量也很重要，比如说比亚迪他是这个行业的龙头企业，但是他之前没有涨停没有成交量也不能介入，因为他没有赚钱效应，没有情绪，所以说核心票一定要有赚钱效应，有情绪，有成交量，这个时候才能介入
      </li>
      <li>
        思考一个问题，如果你连当下的热点都把握不住，你怎么能确保你能抓住下一个热点呢？这里就存在一个悖论，实事求是，活在当下，当下强就是强不要想那么多，大道至简，另外当你判断一个板块有持续性并且会不断发酵的时候，这种节点可以考虑买入低位的补涨股，不一定要买入龙头，比如在25年2月份的拓维信息带动下的deepseek，以及阿里巴巴带动的杭州板块，这个时候不敢买入拓维信息，杭钢股份，浙数文化这种核心票，或者说买不进去，那么你就可以考虑像浙大网新这种有补涨逻辑的个股，即使他不是龙头，那么只要这个板块有持续性和高度，那么它完全可以作为补涨逻辑来操作，也叫做跟风股，但是前提得是这个板块是主流板块并有持续性
      </li>
      <li>
        行情好的时候可以买6个票，每两个核心票为一个方向，一定要做主流。主流板块里面的领涨核心，在行情好的时候，一定要敢于重仓，而不是轻仓，买不到核心，宁愿不要去买，主流板块配方向的话，两个票可以是一个主板一个创业板这样的结合，认知到了，行动力一定要跟上，不然永远是没有办法进步的。一定要学会知行合一
      </li>
      <li>
        1、锚定赚钱效应，情绪，筹码价位就行了，而不是股价，特别是赚钱效应，当没有跌停的时候就是情绪好转的时候，这个时候可以尝试了,情绪都是有周期的,
        我经常说赚钱都是市场给的，至于市场怎么给呢？那就是赚钱效应，至于筹码，那就是这个股票不能有太多的套牢盘，有套牢盘的话谁去给他们解套呢？要思考这个问题
      </li>
      <li>
        2、92科比说的周期理论，主升，高位震荡，主跌，低位试错，当进入主跌行情的时候也就是有七八个股票跌停赚钱效应不好的时候，这个时候就考虑空仓休息了，这个时间只要大环境不是很恶劣一般都要三天差不多，然后等主跌周期结束之后，就可以开始低位试错了
      </li>
      <li>
        3、如果要进攻做右侧就要锚定五日线和十日线，破位了就要走就不能看了，如果想要防守做左侧，就要锚定20日线和60日线，如果破了20日线就要往60日线看了，做交易不能只做防守，不做进攻，这也是很重要的一点
      </li>
      <li>
        4、如果做右侧也就是锚定5日线和10日线的操作，当拉出最后一根大阳线的时候，然后隔天出现阴线的时候，这个时候是千万不能买，在这个位置要在最后一根阳线的基础上观察四到五天，看能不能站稳10日线，如果能站稳不创新低就可以买入了
      </li>
      <li>
        5、玩A股玩的就是情绪，自己可以好好感受一下是不是，所以行情不好千万不要有赚钱的想法，可以考虑休息一下
      </li>
      <li>
        6、玩A股就像是打游戏，必须注意细节，一场比赛想要赢，必须猥琐发育，注意各种细节，不然浪一波就要功亏一篑，本来赢的局面变成了输的局面，如果一直连败就要考虑休息一下
      </li>
      <li>7、玩A股一定要管住手，否则盈利一下就能给你干回去</li>
      <li>
        8、行情不好的时候，不亏，小亏都是赚，你想一想，如果你没有管住手，当天亏钱了，你第二天大概率还要亏钱，相当于你如果没有管住手你要亏两次钱
      </li>
      <li>
        9、买股票一定要买向上趋势的，千万不要买向下趋势的，想一下，一只股票想赚钱，必须拉升，拉升他就意味肯定是向上走的，向上都是获利盘，而向下就全是套牢盘，难道你要去解放套牢盘吗，不要想着是洗盘什么的，没必要，性价比不高
      </li>
      <li>
        10、如果要左侧买入，最好买那种价格高于日内均线的，或者买入在均线附近上下跳动的
      </li>
      <li>
        11、想想北京炒家，这么大的体量人家都能忍住不动手，所以才走到了今天，管住手这个优点真的要向他学习，再想想你朋友圈的那些人，你真的比他们差吗？不，你是缺少机会，而管住手将会让你的机会更多，赢的几率更大
      </li>
      <li>12、当你在看这些话的时候是不是又想买卖了，忍住</li>
      <li>
        13、亏钱宁愿亏在右侧，也不要亏在左侧，在A股玩股票还是要多玩玩右侧交易
      </li>
      <li>
        14、记住，一切的你想买卖的冲动都是因为情绪，你要学会控制自己，控制自己的情绪
      </li>
      <li>
        15、不一定要分六个票,当行情不好的时候买，最多买三个票，因为要进行战略收缩，你开的票越多，可能亏得越多，所以说行情不好最好买三个票，如果到了行情好的时候可以买六个票，这个时候就会出现东边不亮西边亮的情况，千万别搞反了，比如说行情不好的时候你开六个票，然后想着东边不亮西边亮，这是完全相反的错误的操作，因为行情不好的时候，大部分票都是下跌的，你开的票越多亏的就越多，这个时候一定要进行战略收缩
      </li>
      <li>
        16、玩A股一定要认识到自己的能力，就和打游戏一样有些人他打游戏就是有天赋。但是有些人他打游戏就是不行，这也是一种能力的体现是一种天赋的体现一定要有一个新型的自我认知你可以选择苟分你也可以选择钢枪，如果你没有那个能力就不要选择去钢枪，苟分一样的可以上分，如果只是无脑钢枪，只会死亡然后一落千丈
      </li>
      <li>
        17、个股的走势我们作为小散户是预判不了引导不了的，我们能做的就是在自己的模式内专注自己的手法就行了，在自己的模式内等风来就行了，不用太在意涨跌了，太在意涨跌了真的很难做好，因为他会放大你的情绪，你的情绪放大了你的判断就会出现问题，所以说在自己的模式内等风来就行了
      </li>
      <li>
        18、看到跌的你去抄底觉得他不会在跌了，看到涨的你不敢去追，觉得他不会在涨了，这里本身就存在一个悖论，所以模式很重要，不要太在意涨跌了
      </li>
      <li>19、个股先于板块，板块先于指数，以小见大</li>
      <li>
        20、有赚钱效应自然就会打开高度，人气所在，互联网时代，没有增量资金，需要吹票聚集资金，打造赚钱效应氛围
      </li>
      <li>21、顺势而为顺势而为顺势而为，资金认可就行，你不认可没用</li>
      <li>
        22、炒股票开盘之后一定要先观察外部的情况不能只盯着自己要先观察外部外部情况不对之后再看自己的票。当然了，我说出这句话的时候说明我买的票根本不是核心，而是要看别人的脸色的
      </li>
      <li>23、最重要的一点 a股蠢人多 题材第一时间出来 一定要勇于参与</li>
      <li>
        24、一件事情，每次都是把它往坏的的地方想，从来没有考虑过，如果你一直往好的地方想是不是会更好，你往坏的结果想可能会越来越坏，但是从来没有思考过往好的方向思考试是不是会有越来越好，在股市也是一样的，如果你一直觉得跌了他会一直跌，他不会再涨，这都是不对的，这是属于两个极端，一定要相信自己，既然他往坏的地方他会越来越坏，换一个思考路径，那他往好的地方是会越来越好的
      </li>
    </ul>
    <!-- <div class="img_warpper">
      <img
        v-for="(item, index) in imgList"
        :key="index"
        :src="require(`@imgs/${index + 1}.jpeg`)"
      />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      imgList: new Array(8).fill(1),
      isPlaying: false,
      isLooping: false,
      speed: 0.6,
      utterance: null,
      currentCharIndex: 0
    }
  },
  methods: {
    togglePlay() {
      if (this.isPlaying) {
        speechSynthesis.pause()
      } else {
        if (!this.utterance || speechSynthesis.speaking === false) {
          this.createUtterance()
          speechSynthesis.speak(this.utterance)
        } else {
          speechSynthesis.resume()
        }
      }
      this.isPlaying = !this.isPlaying
    },
    changeSpeed() {
      this.speed = this.speed < 2 ? this.speed + 0.2 : 0.6
      if (this.utterance) {
        this.utterance.rate = this.speed
        if (this.isPlaying) {
          speechSynthesis.cancel()
          this.createUtterance(this.currentCharIndex)
          speechSynthesis.speak(this.utterance)
        }
      }
    },
    resetSpeed() {
      this.speed = 0.6
      if (this.utterance) {
        this.utterance.rate = this.speed
        if (this.isPlaying) {
          speechSynthesis.cancel()
          this.createUtterance(this.currentCharIndex)
          speechSynthesis.speak(this.utterance)
        }
      }
    },
    toggleLoop() {
      this.isLooping = !this.isLooping
    },
    createUtterance(startIndex = 0) {
      const text = this.getPageText().slice(startIndex)
      this.utterance = new SpeechSynthesisUtterance(text)
      this.utterance.rate = this.speed
      this.utterance.onboundary = (event) => {
        if (event.name === 'word') {
          this.currentCharIndex = event.charIndex
        }
      }
      this.utterance.onend = () => {
        if (this.isLooping) {
          this.currentCharIndex = 0
          this.createUtterance()
          speechSynthesis.speak(this.utterance)
        } else {
          this.isPlaying = false
        }
      }
    },
    getPageText() {
      const elements = document.querySelectorAll('.hello ul, .hello h1')
      return Array.from(elements)
        .map((el) => el.textContent.trim())
        .join(' ')
    }
  }
}
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
}
.controls {
  text-align: center;
  margin-bottom: 20px;
}
button {
  margin: 5px;
  padding: 10px 15px;
  background-color: #42b983;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #359769;
}
.img_warpper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
}
img {
  width: 300px;
  height: 100%;
  margin-bottom: 60px;
}
</style>
